
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<div class="vnc-settings-component">
  <vnc-dialog *ngIf="!mobileSettings" [classes]="['vp-settings']" [headerTemplate]="headerTemplate"
              classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
  </vnc-dialog>
  <vnc-dialog *ngIf="mobileSettings" [classes]="['vnc-settings-mobile']" headerText="{{ 'FLUID_SETTINGS' | translate }}"
              classes="fixed-size with-border with-border-radius" [headerTemplate]="headerTemplate" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
  </vnc-dialog>
<!-- header -->
  <ng-template #headerTemplate>
    <vnc-mobile-header *ngIf="mobileSettings && selectedTab === 'ALL'" style="width: 100%;" (backButtonClick)="close()" headerType="back-more" mobileHeading="{{ 'SETTINGS' | translate }}"></vnc-mobile-header>
    <vnc-mobile-header *ngIf="mobileSettings && selectedTab !== 'ALL'" style="width: 100%;" (rightButtonClick)="updateSettings()" rightButtonIcon="mdi-check" customClass="white-back-more" (backButtonClick)="backToSettings()" headerType="back-more" mobileHeading="{{ settingLabel | translate }}"></vnc-mobile-header>

    <vnc-header *ngIf="!mobileSettings" style="width:100%;" title="{{ 'FLUID_SETTINGS' | translate }}" type="title-close" size="l"  (onCloseClick)="close()" [showCloseIcon]="true" closeIconToolTip="{{'CLOSE' | translate}}"></vnc-header>
  </ng-template>
<!--body-->
  <ng-template #bodyTemplate>
    <form [formGroup]="settingsForm" [class.hide-tabs]="selectedTab !== 'ALL' && mobileSettings" class="smooth-scroll" (submit)="$event.preventDefault();">
      <vnc-vertical-tabs [activeFirstTab]="!mobileSettings" fixedWidth="280px" (selected)="onTabChange($event)">
        <vnc-vertical-tab tabTitle="{{ 'GENERAL' | translate }}" iconName="setting" type="icon-left" id="globalSettings">

          <div class="general_settings_dialog commander-dialog">
            <div class="commander__dialog__header desktop_view">
              <div class="header_title disable-select">
                {{ 'GENERAL_SETTINGS' | translate }}
              </div>
            </div>
            <div class="commander__dialog__body settings-form">
              <div class="language_select disable-select">
                <mat-form-field>
                  <mat-label class="option-label">{{ 'LANGUAGE'| translate}}</mat-label>
                  <mat-select panelClass="dialog-mat-selection" formControlName="selectedLanguage">
                    <mat-option *ngFor="let language of languageNames" [attr.id]="language.elementId" [value]="language.value">
                      {{language.display | translate}}
                      <mat-icon *ngIf="language.value === selectedLanguage.value" matSuffix fontSet="mdi" fontIcon="mdi-check" style="vertical-align: inherit;"></mat-icon>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="server_url" *ngIf="isCordovaOrElectron">
                <mat-form-field>
                  <input matInput placeholder="{{'SERVER_URL'|translate}}" [(ngModel)]="serverURL">
                </mat-form-field>
              </div>
            </div>
          </div>
        </vnc-vertical-tab>

        <vnc-vertical-tab  tabTitle="{{'APPLICATION'|translate}}" type="icon-left" iconName="feature" id="featuresSettings">
          <ng-container [ngTemplateOutlet]="featureTab"></ng-container>
        </vnc-vertical-tab>
      </vnc-vertical-tabs>
    </form>
  </ng-template>
<!-- footer -->
  <ng-template #footerTemplate>
    <ng-container *ngIf="!mobileSettings">
    <div class="footer-actions footer-buttons">
      <vnc-link-button
        [size]="'m'"
        label="{{ 'CANCEL' | translate }}"
        (onClick)="close()"
        [isFixedSize]="true"
        [fixedSize]="96"
      ></vnc-link-button>
      <vnc-primary-button
        [size]="'m'"
        (onClick)="updateSettings()"
        [isFixedSize]="true"
        [fixedSize]="96"
      ><span>{{ 'SAVE' | translate }}</span>
      </vnc-primary-button>
    </div>
    </ng-container>
  </ng-template>

</div>
<ng-template #featureTab>
  <div class="general_settings_dialog commander-dialog">
  <div *ngIf="!mobileSettings" class="setting-row setting-title">
    <div class="setting-heading">
      {{ 'APPLICATION' | translate }}
    </div>
  </div>
  <div class="setting-row" *ngFor="let item of appSettings; let i = index">
    <vnc-toggle-switch labelPosition="before" [checked]="item.isChecked" (changeEvent)="changeAppSettings($event, i, item.appName)" [size]="'s'">
      {{ item.title | translate }} <br>
      <div class="option-sub-desc">
        {{ item.description | translate }}
      </div>
    </vnc-toggle-switch>
  </div>
  </div>
</ng-template>
