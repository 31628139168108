
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState } from "../reducers";

@Injectable()
export class DatetimeService {

  static SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY = "SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY";

  static MIN_ALLOWED_TIME_DIFF = 5 * 1000; // 5 seconds

  localAndServerTimeDiffInterval = 0;

  constructor( private store: Store<RootState>) {
    this.localAndServerTimeDiffInterval = this.getServerAndLocalTimeDiffIntervalFromStorage();
  }

  getDisplayTime(timestamp: number): number {
    const displayTime = timestamp + this.localAndServerTimeDiffInterval;
    // this.logger.info("[DatetimeService][getDisplayTime]", timestamp, this.localAndServerTimeDiffInterval, displayTime);
    return displayTime;
  }

  private getServerAndLocalTimeDiffIntervalFromStorage(): number {
    return parseInt(localStorage.getItem(DatetimeService.SERVER_LOCAL_TIME_DIFF_LOCAL_STORAGE_KEY) || "0");
  }
}
