/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { select, Store } from "@ngrx/store";
import { distinctUntilChanged, take } from "rxjs/operators";
import { getLastPhotoUpdateByEmail, getOnlineStatus } from "src/app/reducers";
import { SetLastPhotoUpdate } from "src/app/actions/app";
import { AppState } from "src/app/reducers/app";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { CommonUtils } from "src/app/common/utils/common-util";

@Component({
  selector: "vp-avatar",
  templateUrl: "./user-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges, OnDestroy {
  @Input() type;
  @Input() user;
  @Input() chatType;
  @Input() avatarURL: string;
  photoLastUpdate: any;
  photoLastUpdateSubscription$: any;
  email: string;
  firstLastCharacters = "NA";
  colorList = ["#317bbc", "#31bc9e" , "#4bbc31" , "#b2bc31" , "#bc6831", "#ac1717" , "#8c6e98"]
  constructor(private config: ConfigService, private changeDetectionRef: ChangeDetectorRef, private store: Store<AppState>,
    private electronService: ElectronService) {
  }

  getColor() {
    let color = "#ffffff";
    if (this.user) {
      if (!this.avatarURL && this.user.color) {
        color = this.user.color;
      }
    }
    if (this.chatType && this.chatType === "broadcast") {
      color = "#00c0a5";
    }
    if (this.chatType && this.chatType === "email-avatar" || this.chatType === "email-avatar-tab" ) {
      this.user.firstLastCharacters =  this.user.firstLastCharacters.toUpperCase();
      switch (this.user.firstLastCharacters) {
        case "A":
        case "B":
        case "C":
        case "D":
        case "E":
          {
            color = this.colorList[0];
            break;
          };
        case "F":
        case "G":
        case "H":
        case "I":
        case "J":
          {
            color = this.colorList[1];
            break;
          };
        case "K":
        case "L":
        case "M":
        case "N":
        case "O":
          {
            color = this.colorList[2];
            break;
          };
        case "P":
        case "Q":
        case "R":
        case "S":
        case "T":
          {
            color = this.colorList[3];
            break;
          }
        default : {
          color = this.colorList[4];
          break;
        }

      }
      // console.log("this.user.firstLastCharacters", this.user.firstLastCharacters, color)
    }

    return color;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.user) {
      const email = this.user.email || this.user.emails;
      this.email = this.user.defaultMail;
      if (this.user.email) {
        if (Array.isArray(this.user.email)) {
          this.email = this.user.email[0];
        } else {
          this.email = this.user.email;
        }
      }

      if (this.user.firstLastCharacters) {
        this.firstLastCharacters = this.user.firstLastCharacters;
      }
      if (this.user.firstName && this.user.lastName) {
        this.firstLastCharacters = CommonUtils.getCharacters(this.user.firstName, this.user.lastName);
      } else if (this.user.first_name && this.user.last_name) {
        this.firstLastCharacters = CommonUtils.getCharacters(this.user.first_name, this.user.last_name);
      }
      if (this.photoLastUpdateSubscription$) {
        this.photoLastUpdateSubscription$.unsubscribe();
      }
      this.photoLastUpdateSubscription$ = this.store.select(state => getLastPhotoUpdateByEmail(state, this.email))
        .subscribe(photoLastUpdate => {
          if (!!photoLastUpdate) {
            this.photoLastUpdate = photoLastUpdate;
          } else {
            this.photoLastUpdate = new Date().getTime();
            this.store.dispatch(new SetLastPhotoUpdate({ email: this.email, timestamp: this.photoLastUpdate }));
          }
          this.buildAvatarURL();
        });
    }
  }

  ngOnDestroy() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
  }

  imgLoadOnError() {
    this.avatarURL = null;
    this.changeDetectionRef.markForCheck();
  }

  private avatarVersion() {
    let version;
    const currentTimestamp = new Date().getTime();
    // // load new ver of avatar
    if ((!this.photoLastUpdate
      || (this.photoLastUpdate > 0 && currentTimestamp - this.photoLastUpdate >= this.config.AVATAR_SYNC_INTERVAL)
      || (this.photoLastUpdate < 0 && currentTimestamp - Math.abs(this.photoLastUpdate) >= this.config.AVATAR_SYNC_INTERVAL)
    )
    ) {
      // set new version
      version = "?ver=" + currentTimestamp;
      // use old ver
    } else {
      if (this.photoLastUpdate && this.photoLastUpdate > 0) {
        version = "?ver=" + this.photoLastUpdate;
      } else {
        version = null; // no version available yet, so show a default image
      }

    }
    return version;
  }

  private buildAvatarURL() {
    if (this.chatType === 'email-avatar' || this.chatType === 'email-avatar-tab') {
      return;
    }
    let isOnline = false;
    this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), take(1)).subscribe(v => {
      isOnline = v;
    });
    if (isOnline) {
      const avatarVersion = this.avatarVersion();
      let avatarId = "";
      if (environment.isElectron) {
        avatarId = this.electronService.md5(this.email);
      } else {
        avatarId = md5(this.email);
      }


      if (this.config.get("avatarURL")) {
        this.avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg${avatarVersion}`;
        this.changeDetectionRef.markForCheck();
      }
    }
  }
}
