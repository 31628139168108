/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AppRepository } from "../repositories/app.repository";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { take, takeUntil } from "rxjs/operators";
import { DocumentListComponent } from "../document-list/document-list.component";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "vp-search",
  templateUrl: "./search.component.html"
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  keyword: string;
  totalResults = 0;
  showSearchNameDialogue = false;
  searchNameCtrl = new UntypedFormControl("");
  filters: any;

  @ViewChild("documentList") documentList: DocumentListComponent;
  private isAlive$ = new Subject<boolean>();
  constructor(private activeRoute: ActivatedRoute,
              private broadcaster: Broadcaster,
              private router: Router,
              private matDialog: MatDialog,
              private appRepository: AppRepository) { }

  ngOnInit() {
    this.activeRoute
      .queryParams
      .pipe(takeUntil(this.isAlive$))
      .subscribe(params => {
       
        if (this.keyword !== params.q) {
          this.keyword = params.q;
          this.appRepository.setSearchString(params.q);
          this.broadcaster.broadcast("triggerSearch");
        }
      });

    this.appRepository.getFilters().pipe(take(1)).subscribe(v => {
      if (!!v && v.rawFilterValues) {
        this.filters = v.rawFilterValues;
      }

      if (!!v && v.filters) {
        this.filters = {...this.filters, tags: v.filters.selectedTags};
      }
    });
  }

  ngAfterViewInit(): void {
    this.documentList.totalResults$.asObservable().pipe(takeUntil(this.isAlive$)).subscribe(val => {
     
      this.totalResults = val;

    });
  }

  closeSearch() {
    this.appRepository.setSearchString("");
    this.router.navigate(["/"]);
    this.broadcaster.broadcast("closeSearch");
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  async refineSearch() {
    this.broadcaster.broadcast("OPEN_ADVANCE_SEARCH_BROADCAST")
  }

  openSearchNameDialogue() {
    this.showSearchNameDialogue = true;
  }

  closeSearchNameDialogue() {
    this.showSearchNameDialogue = false;
    this.searchNameCtrl.reset();
  }

  saveSearch() {
    if (!!this.searchNameCtrl.value) {
      const name = this.searchNameCtrl.value;
      const body = {
        searchTerm: this.keyword,
        name,
        filters: this.filters
      };
      this.appRepository.addSearch(body).subscribe(
        res => {
          this.showSearchNameDialogue = false;
          this.searchNameCtrl.reset();
          this.appRepository.setSearchString("");
          this.router.navigate(["/"]);
          this.broadcaster.broadcast("closeSearch");
         
        }
      );

    }
  }
}
