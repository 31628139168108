
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
  <vnc-dialog [classes]="['about-dialog-component']" headerText="{{ 'APP_INFO' | translate }}" closeText="{{ 'CLOSE' | translate }}"
  classes="fixed-size with-border with-border-radius"
  [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<ng-template #mobileHeader>
  <vnc-mobile-header style="width: 100%;" customClass="white-back-more" (backButtonClick)="close()"
    headerType="back-more" mobileHeading="{{ 'APP_INFO' | translate }}"></vnc-mobile-header>
</ng-template>

<ng-template #bodyTemplate>
  <div class="version_dialog" [style.backgroundImage]="backgroundImage">
    <div class="commander__dialog-body commander__dialog-body--pad">
      <div class="content">
        <img *ngIf="!isVNCFluidBuild" alt="" class="app_icon disable-select" [src]="appLogo">
        <svg *ngIf="isVNCFluidBuild" width="140" height="140" viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
          <g transform="rotate(-1 130.559 -22.468)" fill="none" fill-rule="evenodd">
              <circle fill="#0F89F4" fill-rule="nonzero" cx="60.946" cy="60.946" r="60.946"/>
              <path d="M60.866 12.632 45.215 37.518l-.26.33a20.674 20.674 0 0 0-4.325 12.686c0 11.36 9.096 20.57 20.316 20.57 11.22 0 20.315-9.21 20.315-20.57l-.004-.396a20.667 20.667 0 0 0-4.272-12.228l-.04-.05-16.08-25.228z" fill="#FFF"/>
              <circle fill="#FFF" opacity=".3" cx="60.946" cy="68.564" r="20.315"/>
              <circle fill="#FFF" opacity=".3" cx="60.946" cy="81.261" r="20.315"/>
          </g>
        </svg>

        <h6 class="app_version_name">
          <div class="logo-title" *ngIf="!isVNCFluidBuild">
            <span class="disable-select prefix_text">{{prefixBold}}</span>
            <span class="product_name disable-select">{{suffixNormal}}</span>
          </div>
          <div class="logo-title" *ngIf="isVNCFluidBuild">
            <span class="disable-select prefix_text">VNC</span>
            <span class="product_name disable-select">fluid</span>
          </div>
        </h6>
        <span class="app_version disable-select" (click)="copyToClipboard()" matTooltip="{{'COPY_TO_CLIPBOARD' | translate }}">
          {{ 'VERSION_LBL' | translate }} {{ app_version }}
        </span>
        <p *ngIf="!isVNCFluidBuild" id="mobile_sidebar_about_version_changelog" class="change_log">
          <a class="open-new-window disable-select" href="{{changeLog}}" target="_blank">{{ 'CHANGELOG'
            | translate }}</a>
        </p>
      </div>
      <h3 *ngIf="isVNCFluidBuild" class="changelog-menu">
          <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 7.875c-.623 0-1.125.503-1.125 1.125S2.377 10.125 3 10.125 4.125 9.622 4.125 9 3.623 7.875 3 7.875zm0-4.5c-.623 0-1.125.502-1.125 1.125S2.377 5.625 3 5.625 4.125 5.123 4.125 4.5 3.623 3.375 3 3.375zm0 9.127a.997.997 0 1 0 0 1.995.997.997 0 0 0 0-1.995zm2.25 1.748h10.5v-1.5H5.25v1.5zm0-4.5h10.5v-1.5H5.25v1.5zm0-6v1.5h10.5v-1.5H5.25z"
              fill="#FFF" fill-rule="evenodd" />
          </svg> <a target="_blank" class="open-new-window"
          href="{{changeLog}}">{{ 'CHANGELOG'|translate }}</a>
      </h3>
    </div>
  </div>
</ng-template>
