import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { Action } from "../actions";
import { ITicket } from "../common/models/ticket.models";
import { TicketActionTypes } from "../actions/tickets";

export interface TicketStates {
  isLoading: boolean;
  isLoaded: boolean;
  allTicket: ITicket[];
}

export const _getTicketList = (state: TicketStates) => state.allTicket;

export const ticketAdapter: EntityAdapter<ITicket> = createEntityAdapter<ITicket>({
  selectId: (ticket: ITicket) => ticket.id,
});

export const initialState: TicketStates = {
  isLoading: false,
  isLoaded: false,
  allTicket: []
};

export const _getTicketIsLoading = (state: TicketStates) => state.isLoading;
export const _getAllTicketList = (state: TicketStates) => state.allTicket;
export const _getSelectedTicketId = (state: TicketStates) => state.allTicket[0].id;

export function ticketReducer(state: TicketStates = initialState, action: Action): TicketStates {
  switch (action.type) {
    case TicketActionTypes.TICKET_LIST_ADD: {
      const newTicket = action.payload;
      return {
        ...state,
        allTicket: [
          ...state.allTicket,
          newTicket
        ]
      };

    }
    default: {
      return state;
    }
  }
}
