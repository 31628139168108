/*
 * VNC Library
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject, empty } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'vp-input-chips',
    templateUrl    : "./tag-select.component.html",
    encapsulation: ViewEncapsulation.None
})
export class VPInputChipsComponent implements OnChanges {

    separatorKeysCodes: number[] = [ENTER, COMMA];
    itemCtrl = new FormControl();
    filteredItems: Observable<string[]> = empty();
    // Material Icon
    @Input() iconName = '';
    @Input() visible = true;
    @Input() selectable = true;
    @Input() removable = true;
    @Input() required = true;
    @Input() items: string[] = [];
    @Input() allItems: string[] = [];
    @Input() label = 'Tags';
    @Input() newItemLabel = '';
    // Event emitted when the selected chip list value has been changed by the user.
    @Output() change = new EventEmitter<any>();
    @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete!: MatAutocomplete;
    @Output() onSearch = new EventEmitter<any>();
    debouncer: Subject<any> = new Subject();
    constructor() {

        this.debouncer.pipe(debounceTime(500)).subscribe(value => {
            let searchText = this.itemInput!.nativeElement.value;
            this.onSearch.emit(searchText);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allItems) {
            this.filteredItems = this.itemCtrl.valueChanges.pipe(
                startWith(null),
                map((item: string | null) => item ? this._filter(item) : this.allItems.slice()));
        }
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our item
        if ((value || '').trim() && this.items.indexOf(value.toLowerCase()) === -1) {
            this.items.push(value.trim().toLowerCase());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.itemCtrl.setValue(null);
    }

    remove(item: string): void {
        const index = this.items.indexOf(item);

        if (index >= 0) {
            this.items.splice(index, 1);
        }

        this.change.emit(this.items);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (this.items.indexOf(event.option.viewValue.toLowerCase()) === -1) {
            this.items.push(event.option.viewValue.toLowerCase());
        }

        this.itemInput.nativeElement.value = '';
        this.itemCtrl.setValue(null);
        this.change.emit(this.items);

    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allItems.filter(item => item.toLowerCase().indexOf(filterValue) === 0);

    }

    onChange($event: any) {
        console.log("change list call", $event)
        this.change.emit($event);
    }

    public onKeydown($event: any): void {
        this.debouncer.next(true);
    }


}
