/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone, OnInit } from "@angular/core";
import { AuthService } from "./common/providers/auth.service";
import { ConfigService } from "./config.service";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { select, Store } from "@ngrx/store";
import { getOnlineStatus, getSidebarStatus, RootState, getConversationState, getAllConversation, getConversations, getTotalConversations, getEmbeddedTalkReady } from "./reducers";
import { DeviceReady, OnlineStatus, SetAvailableApps, SetUserProfile, SetFluidTotalUnread, SetEmbeddedTalkReady } from "./actions/app";
import { environment } from "src/environments/environment";
import { filter, take, takeUntil } from "rxjs/operators";
import { ToastService } from "./common/providers/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { AppRepository } from "./repositories/app.repository";
import { CommonUtils } from "./common/utils/common-util";
import { Contact } from "./common/models/ldap-contact.model";
import * as QuillNamespace from "quill";
import { IconsService } from "./common/providers/icons.service";
import { SelectActivityDialogComponent } from "./select-activity-dialog/select-activity-dialog.component";
import { StartChatComponent } from "./start-chat/start-chat.component";
import { MessagingService } from "../../service/messaging.service";
import { ConversationLoadSuccess, ConversationNextLoadSuccess } from "./actions/conversation";
import { DomSanitizer } from "@angular/platform-browser";
// import { StartChatComponent } from "./start-chat/start-chat.component";
// import { SelectActivityDialogComponent } from "./select-activity-dialog/select-activity-dialog.component";
// import { ComposeMailComponent } from "src/app/compose-mail/compose-mail.component";
// import { ComposeTaskComponent } from "./task-compose";

@Component({
  selector: "vp-app",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  isLoggedIn: boolean;
  isSidebarOpened = true;
  showActions: boolean;
  isSidebarExpanded: boolean;
  showFilters: boolean;
  isVNCFluidBuild = environment.isVNCFluidBuild;
  private embeddedTalkReady: boolean = false;
  mailRoute: any;
  constructor(
    private auth: AuthService,
    private changeDetectionRef: ChangeDetectorRef,
    public configService: ConfigService,
    private broadcaster: Broadcaster,
    private router: Router,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private store: Store<RootState>,
    private toastService: ToastService,
    private matIconRegistry: MatIconRegistry,
    private translate: TranslateService,
    private appRepository: AppRepository,
    private iconsService: IconsService,
    private matDialog: MatDialog,
    private messagingService: MessagingService,
    private sanitizer: DomSanitizer
  ) {
    this.matIconRegistry.registerFontClassAlias("mdi");
    this.iconsService.addIcons();
    this.checkInternetConnection();
    this.store.select(getSidebarStatus).subscribe(v => {

      this.isSidebarExpanded = v;
      this.changeDetectionRef.markForCheck();
    });
    this.appRepository.getShowFilters().subscribe((value: boolean) => {
      this.showFilters = value;
      this.changeDetectionRef.markForCheck();
    });

    if (environment.isCordova) {
      document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);
    }
  }

  private onDeviceReady(): void {


    this.handleBackButton();

    document.addEventListener("online", this.handleConnectionChange.bind(this), false);
    document.addEventListener("offline", this.handleConnectionChange.bind(this), false);

    if (environment.isCordova && typeof navigator !== "undefined" && navigator.splashscreen) {
      navigator.splashscreen.hide();

    }

    document.addEventListener("pause", () => {
      window.appInBackground = true;
      localStorage.setItem("lastTimeInBackground", new Date().getTime().toString());
    });

    document.addEventListener("resume", () => {

    });
    if (typeof Keyboard !== "undefined") {
      try {
        Keyboard.shrinkView(true);
      } catch (ex) {

      }
    }
    if (CommonUtils.isOnAndroid()) {
      CommonUtils.requestPermissions();
    }

    if (screen && screen.orientation && screen.orientation.lock && screen.orientation.unlock) {
      if (CommonUtils.isOnIOS() && !CommonUtils.isOnIpad()) {
        screen.orientation.lock("portrait");
      } else {
        screen.orientation.unlock();
      }
    } else {
      console.warn("[AppComponent][onDeviceReady] screen/screen.orientation undefined", screen);
    }

    this.store.dispatch(new DeviceReady(true));

    StatusBar.show();
    if (CommonUtils.isOnIOS()) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

  private handleBackButton(): void {

    document.addEventListener("backbutton", (e) => {

        if (document.querySelector("#loginIframe") !== null) {
          navigator.app.exitApp();
        }
      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      }, 20);
    }, false);
  }

  private checkInternetConnection() {
    this.store.dispatch(new OnlineStatus(navigator.onLine));
    if (!environment.isCordova) {
      window.addEventListener("online", this.handleConnectionChange.bind(this));
      window.addEventListener("offline", this.handleConnectionChange.bind(this));
    }
  }

  private handleConnectionChange(event): void {

    if (!navigator.onLine) {

      localStorage.setItem("lastTimeOnline", new Date().getTime().toString());
    }
    this.store.dispatch(new OnlineStatus(navigator.onLine));
  }

  ngOnInit() {
    this.setupEmoji();
    this.appRepository.restoreFromState();
    this.store.pipe(select(getOnlineStatus), filter(v => !!v), take(1)).subscribe(isOnline => {
      console.log("isOnline", isOnline);
      this.loadProfile();
    });

    this.store.select(getEmbeddedTalkReady).subscribe(ready => {
      this.embeddedTalkReady = ready;
    });


    const Quill: any = QuillNamespace;
    window.sharedQuill = Quill;
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.currentMessage.subscribe(
      message => {

      }
    );

    // this.appRepository.getMissingGermanKeys(); // TO use for finding missing german keys.
  }

  private loadProfile() {
    this.auth.getProfile().subscribe(res => {

      if (!!res) {
        this.configService.set("avatarURL", res.avatarURL);
        this.appRepository.setupStatePersistance();
        this.appRepository.getAllRoomMembersAndStore();
        this.isLoggedIn = true;
        const user = res.user;
        const fullName = `${user.firstName ? user.firstName.trim() : ""} ${user.lastName ? user.lastName.trim() : ""}`;
        const tmpUser: any = {
          id: user.id, firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          defaultMail: CommonUtils.getDefaultMail(user),
          fullName: fullName.trim()
        };
        const profile = {...res, ...tmpUser};
        if (res.secret) {
          localStorage.setItem("token", res.secret);
        }
        this.store.dispatch(new SetUserProfile(profile));
        if (!!document.getElementById("non-cordova-only")) {
          document.getElementById("non-cordova-only").outerHTML = "";
        }
        this.changeDetectionRef.detectChanges();
        this.appRepository.getMailFolders().pipe(take(1)).subscribe(res => {

        });
        this.appRepository.getAttributes("attrs").pipe(take(1)).subscribe(res => {
          if (!!res && res.attSizeLimit) {
            localStorage.setItem("attSizeLimit", res.attSizeLimit);
          }
        });
        this.appRepository.getMyProducts().pipe(take(1)).subscribe(data => {
          if (data && data.products) {
            localStorage.setItem("products", JSON.stringify(data.products));
            const availableApps = data.products.map(p => {
              let url = p.url;
              if (url && url[url.length - 1] !== "/") {
                url = url + "/";
              }
              return {
                appName: p.name.replace("vnc", "VNC"),
                appNameLowercase: p.name,
                appUrl: url,
                appKey: p.name.toLowerCase().replace("vnc", ""),
                total: 0
              };
            });
            localStorage.setItem("federatedApps", JSON.stringify(availableApps));
            availableApps.forEach(app => {
              if (app.appName === "VNCmail") {
                this.mailRoute = {
                  title: app.appName.replace("vnc", "VNC"),
                  icon: CommonUtils.getIcon(app.appName),
                  nativeLink: `${app.appName.toLowerCase()}://main`,
                  path: app.appUrl,
                  active: true,
                  enabled: true
                };
              }
            });
            this.store.dispatch(new SetAvailableApps(availableApps));
            const mailApp = availableApps.find(app => app.appKey === "mail");
            if (mailApp && mailApp.appUrl) {
              localStorage.setItem("mailAppUrl", mailApp.appUrl);
            }
        }
        });
        this.appRepository.searchLDAP(tmpUser.defaultMail).subscribe(data => {
          if (data && data.length > 0) {
            const c = data[0];
            const contact: Contact = {
              defaultMail: tmpUser.defaultMail,
              name: c.name,
              id: 0,
              ldapData: c.ldapData
            };
            this.appRepository.addContact(contact);
          }
        });

        if (res.URLS) {
          this.configService.URLS = res.URLS;
          localStorage.setItem("URLS", JSON.stringify(res.URLS));
        }
        localStorage.removeItem("lastPollingTimeStamp");
      }
    }, err => {

      if (environment.isCordova || environment.isElectron) {
        this.auth.loginIframe();
      } else {
        window.location.href = "/api/login";
      }
    });
  }

  onSidebarOpened(value) {

    this.isSidebarOpened = value;
    this.changeDetectionRef.markForCheck();
  }

  createEvent() {
    this.appRepository.underDevelopment();
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  createTicket() {
    this.appRepository.underDevelopment();
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  async createTask() {
    const { ComposeTaskComponent } = await import(
      /* webpackPrefetch: true */
      "./task-compose"
      );
    const dialogRef = this.matDialog.open(ComposeTaskComponent, {
      autoFocus: true,
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  createIncident() {
    this.appRepository.underDevelopment();
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  selectActivity() {
    let style: any = {
      width: "465px",
      height: "460px"
    };
    if (CommonUtils.isOnMobileDevice) {
      style = {
        width: "100vw",
        minWidth: "100vw",
        height: "100vh",
        borderRadius: "0"
      };
    }
    const dialogRef = this.matDialog.open(SelectActivityDialogComponent, Object.assign({
      autoFocus: true,
      panelClass: "commander__dialog"
    }, style));

    dialogRef.afterClosed().subscribe((res: any) => {

    });
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  createChat() {
    let style: any = {
      width: "465px",
      height: "560px"
    };
    if (CommonUtils.isOnMobileDevice) {
      style = {
        width: "100vw",
        minWidth: "100vw",
        height: "100vh",
        borderRadius: "0"
      };
    }
    const dialogRef = this.matDialog.open(StartChatComponent, Object.assign({
      autoFocus: true,
      panelClass: "commander__dialog"
    }, style));
    dialogRef.afterClosed().subscribe((res: any) => {

    });
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  async createEmail() {
    const { ComposeMailComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/compose-mail/compose-mail.component"
      );
    const dialogRef = this.matDialog.open(ComposeMailComponent, {
      // width: "750px",
      // height: "560px",
      autoFocus: true,
      panelClass: "commander_compose__dialog"
    });
    dialogRef.afterClosed().subscribe((res: any) => {

    });
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  createCalendar() {
    this.appRepository.underDevelopment();
    this.showActions = false;
    this.changeDetectionRef.markForCheck();
  }

  private setupEmoji() {
    let emojiType = "google";
    let isMac = navigator.platform.toUpperCase().indexOf("MAC") !== -1;
    if (CommonUtils.isOnIOS() || CommonUtils.isOnIOSMobile() || isMac) {
      emojiType = "apple";
    }
    wdtEmojiBundle.defaults.emojiType = emojiType;
    wdtEmojiBundle.defaults.emojiSheets = {
      "google": CommonUtils.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png"),
      "apple": CommonUtils.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
    };
    if (!wdtEmojiBundle.emoji) {

      wdtEmojiBundle.emoji = new EmojiConvertor();

      wdtEmojiBundle.emoji.img_set = emojiType;
      wdtEmojiBundle.emoji.use_sheet = true;
      wdtEmojiBundle.emoji.supports_css = true;
      wdtEmojiBundle.emoji.allow_native = false;
      wdtEmojiBundle.emoji.img_sets = {
        "google": {
          mask: 2,
          path: "emoji-data/img-google-64/",
          sheet: CommonUtils.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png")
        },
        "apple": {
          mask: 2,
          path: "emoji-data/img-apple-64/",
          sheet: CommonUtils.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
        }
      };
      document.querySelector("body").dataset.wdtEmojiBundle = emojiType;

      this.changeDetectionRef.markForCheck();
    }
  }

  @HostListener("window:message", ["$event"])
  windowMessageEventHandler(event: MessageEvent) {
    const eventData = event.data;

    if (eventData.source && eventData.source === "@devtools-page") {
      // Chrome Redux-devtools extension message
      return;
    }
    if (eventData && eventData.type === "VNCTALK_UNREADCOUNT_UPDATE") {
      console.log("fluidAppMessaging receive gettotalunread ", eventData);
      if (!!eventData.unreadcount) {
        this.store.dispatch(new SetFluidTotalUnread({ totalMessages: eventData.unreadcount }));
        if (!this.embeddedTalkReady) {
          this.store.dispatch(new SetEmbeddedTalkReady(true));
        }
      } else {
        this.store.dispatch(new SetFluidTotalUnread({ totalMessages: 0 }));
        if (!this.embeddedTalkReady) {
          this.store.dispatch(new SetEmbeddedTalkReady(true));
        }
      }
    }
    if (eventData && eventData.type === "VNCTALK_CONVERSATIONS_UPDATE") {
      console.log("fluidAppMessaging receive conversations ", eventData);
      if (!!eventData.conversations && (eventData.conversations.length > 0)) {
        const convs = [ ...eventData.conversations ];
        this.store.dispatch(new ConversationNextLoadSuccess( { conversations: convs } ));
        if (!this.embeddedTalkReady) {
          this.store.dispatch(new SetEmbeddedTalkReady(true));
        }
      }
    }

    if (eventData && eventData.type === "VNCMAIL_UPDATE") {
      console.log("fluidAppMessaging receive vncmail ", eventData);

      this.store.dispatch(new SetFluidTotalUnread({ totalEmails: !!eventData.count ? eventData.count : 0, unreadEmails: !!eventData.unreadcount ? eventData.unreadcount : 0 }));
        // ToDo: setEmbeddedMailReady and then ignore fcm?
        // if (!this.embeddedTalkReady) {
        //  this.store.dispatch(new SetEmbeddedTalkReady(true));
        // }

    }

    if (eventData && eventData.type === "VNCCALENDAR_UPDATE") {
      console.log("fluidAppMessaging fluidTotalUnread receive vnccalendar ", eventData);

      this.appRepository.getTotalMeetings().subscribe(v => {
        if (v && v.appt) {
          const totalMeetings = v.appt.filter(a => a.ptst === "NE").length;
          console.log("fluidTotalUnread meetings: ", totalMeetings);
          this.store.dispatch(new SetFluidTotalUnread({ totalMeetings: totalMeetings }));
        }
      });
      this.broadcaster.broadcast("BROADCAST_REFRESH_CALENDAR");
      // ToDo: setEmbeddedMailReady and then ignore fcm?
      // if (!this.embeddedTalkReady) {
      //  this.store.dispatch(new SetEmbeddedTalkReady(true));
      // }

    }

    if (eventData && eventData.type === "VNCTASK_UPDATE") {
      console.log("fluidAppMessaging fluidTotalUnread receive vnctask ", eventData);
      this.broadcaster.broadcast("BROADCAST_REFRESH_VNCTASK");
    }


    if (eventData && eventData.type === "GO_TO_SERVER_URL_PAGE") {

      this.ngZone.run(() => {
        this.configService.selectedServer = false;
        this.changeDetectionRef.markForCheck();
      });
      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }
    } else if (eventData && eventData.type === "VNC_PORTAL_POST_MESSAGE") {

      localStorage.setItem("token", eventData.token);
      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }
      this.store.dispatch(new OnlineStatus(navigator.onLine));
      this.ngZone.run(() => {
        this.store.pipe(select(getOnlineStatus), filter(v => !!v), take(1)).subscribe(isOnline => {
          this.loadProfile();
          this.router.navigate(["/"], { skipLocationChange: true, replaceUrl: true });
          this.changeDetectionRef.markForCheck();
        });
      });
    }
    this.changeDetectionRef.markForCheck();
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {

    let url: string;
    if (event.target.classList.contains("open-new-window")) {
      url = event.target.href;
    }
  }

  onResizeEnd(event) {

  }
  get vncMailUrl() {
    if (!this.mailRoute || !this.mailRoute.path) return this.sanitizer.bypassSecurityTrustResourceUrl("") ;
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.mailRoute.path);
  }
}
