/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { ConfigService } from "src/app/config.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { AppService } from "src/app/services/app.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { CommonUtils } from "src/app/common/utils/common-util";
import {VNCVerticalTabsComponent} from "vnc-library";
import { AppRepository } from "src/app/repositories/app.repository";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "vp-settings",
  templateUrl: "./general-settings-dialog.component.html"
})
export class GeneralSettingsDialogComponent implements OnInit, OnDestroy {

  mobileSettings = (document.body.clientWidth <= 599);
  selectedTab: string = 'ALL';
  @ViewChild(VNCVerticalTabsComponent, {static: false}) tabs: VNCVerticalTabsComponent;
  appSettings = [];

  languageNames: any[] = [
    {
      value: "en",
      display: "ENGLISH",
      elementId: "setLanguageEnglish"
    },
    {
      value: "de",
      display: "GERMAN",
      elementId: "setLanguageGerman"
    }
  ];
  settingsForm: UntypedFormGroup;
  selectedLanguage: UntypedFormControl;
  selectedTheme: string;
  serverURL: string = "";
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  isCordovaApp = environment.isCordova;
  private isAlive$ = new Subject<boolean>();
  themeChangeRestricted: boolean = false;
  translations: any = {};
  constructor(
    public dialogRef: MatDialogRef<GeneralSettingsDialogComponent>,
    private translate: TranslateService,
    private configService: ConfigService,
    private changeDetection: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
    private electronService: ElectronService,
    private appService: AppService,
    private appRepository: AppRepository
  ) {
    this.initialize();
  }

  ngOnInit() {
    this.configService.fieldsInSideBar.subscribe((data) => {
      this.appSettings = data;
    }).unsubscribe();
    this.broadcaster.on<any>(BroadcastKeys.HIDE_GENERAL_SETTINGS).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  initialize() {
    let browserLang = this.translate.getBrowserLang();
    const localLang = localStorage.getItem("portalLanguage");
    if (localLang !== null && localLang !== "undefined") {
      browserLang = localLang;
    }

    this.selectedLanguage = new UntypedFormControl(browserLang);
    this.settingsForm = new UntypedFormGroup({"selectedLanguage": this.selectedLanguage});
    this.initServerURL();
    this.changeDetection.markForCheck();
  }

  close() {
    this.dialogRef.close();
  }

  onTabChange(event) {
    this.selectedTab = event.id;
  }

  updateSettings() {
    if (this.isCordovaOrElectron && this.serverURL.trim() !== null) {
      this.updateServerURL();
    }
    this.updateLanguage();
    this.configService.fieldsInSideBar.next(this.appSettings);
    let data = {};
    this.appSettings.forEach(app => {
      data[app.appName] = app.isChecked
    })
    this.appRepository.saveFluidSettings(data);
    this.close();
  }

  changeAppSettings(event, index, name): void {
    this.appSettings[index].isChecked = event.checked;
  }

  updateLanguage() {
    this.translate.use(this.selectedLanguage.value);
    this.translate.reloadLang(this.selectedLanguage.value);
    this.configService.setCurrentLanguage(this.selectedLanguage.value);

    if (this.electronService.isElectron) {
      this.electronService.setToStorage("portalLanguage", this.selectedLanguage.value);
    } else {
      localStorage.setItem("portalLanguage", this.selectedLanguage.value);
    }
    this.changeDetection.markForCheck();
  }

  initServerURL() {
    this.serverURL = this.configService.API_URL;
    const serverUrl = this.electronService.isElectron
      ? this.electronService.getFromStorage("serviceUrl")
      : localStorage.getItem("serviceUrl");
    if (serverUrl) {
      this.serverURL = serverUrl;
      setTimeout(() => {
        this.changeDetection.markForCheck();
      }, 200);
    }
  }

  updateServerURL() {
    if (this.serverURL === this.configService.API_URL) {
      return;
    }
    if (this.serverURL.endsWith("/")) {
      this.serverURL = this.serverURL.substring(0, this.serverURL.lastIndexOf("/")).trim();
    }
    this.appService.getConfig(this.serverURL).subscribe(res => {
      if (!!res && res.appName && res.appName === "commander") {
        this.configService.API_URL = this.serverURL;
        this.setServerURL(this.configService.API_URL);
      } else {
        this.wrongServerMessage();
      }
    }, err => {
      this.wrongServerMessage();
    });
  }

  wrongServerMessage() {
    this.translate.get("WRONG_SERVER_URL_ERR_MSG").pipe(take(1)).subscribe(text => {
      alert(text);
    });
  }

  setServerURL(url) {
    if (url) {
      if (!url.startsWith("http")) {
        url = "";
      }
      this.configService.setAPIURL(url);

      let serverUrl: string;
      if (this.electronService.isElectron) {
        serverUrl = this.electronService.getFromStorage("serverURL");
        localStorage.clear();
        this.electronService.clearStorage();
        this.electronService.setToStorage("serverURL", url.trim());
        localStorage.setItem("serverURL", url.trim());
      } else {
        serverUrl = localStorage.getItem("serverURL");
        localStorage.clear();
        localStorage.setItem("serverURL", url.trim());
      }

      if (serverUrl && environment.isCordova) {
        this.configService.selectedServer = true;
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
        initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref;
        window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
        this.configService.loginIframe();
      } else {
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
        initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref;
        window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
      }
    }
  }

  backToSettings() {
    this.tabs.selectTabById("ALL");
    this.selectedTab = "ALL";
  }

  get settingLabel() {
    switch(this.selectedTab) {
      case "globalSettings": return "GENERAL";
      case "featuresSettings": return "APPLICATION";
      default: return "SETTINGS";
    }
  }

}
