import { createAction } from "@ngrx/store";
import { Action } from ".";
import { ITicket } from "../common/models/ticket.models";

export class TicketActionTypes {
    static TICKET_LIST_ADD = "[TICKET] Add Ticket List";
}

export const AllTicketList = createAction("[TICKET] Add Ticket List");

export class TicketAddList implements Action {
    readonly type = TicketActionTypes.TICKET_LIST_ADD;
    constructor(public payload: {ticket: ITicket[]}) {
        console.log('inside dispatch')
    }
}
