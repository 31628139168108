import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";
import { ITask } from "../common/models/task.model";
import { AllTaskList, TaskActionTypes } from "../actions/task";
import { createReducer, on } from "@ngrx/store";

export interface TaskStates {
  isLoading: boolean;
  isLoaded: boolean;
  allTask: ITask[];
}

export const _getTaskList = (state: TaskStates) => state.allTask;

export const taskAdapter: EntityAdapter<ITask> = createEntityAdapter<ITask>({
  selectId: (task: ITask) => task.id,
});

export const initialState: TaskStates = {
  isLoading: false,
  isLoaded: false,
  allTask: []
};

export const _getTaskIsLoading = (state: TaskStates) => state.isLoading;
export const _getAllTaskList = (state: TaskStates) => state.allTask;
export const _getSelectedTaskId = (state: TaskStates) => state.allTask[0].id;

export function taskReducer(state: TaskStates = initialState, action: Action): TaskStates {
  switch (action.type) {
    case TaskActionTypes.TASK_LIST_ADD: {
      console.log('action==>', action, state);
      const newTask = action.payload;
      return {
        ...state,
        allTask: [
          ...state.allTask,
          newTask
        ]
      };

    }
    default: {
      return state;
    }
  }
}