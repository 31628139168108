<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div fxLayout="row" id="document-top-bar" *ngIf="isLoggedIn && !isSearchView">
    <div class="document-option">

      <div class="filter-container">
        <span class="global_text_change">Global </span>

          <vnc-chips
            (clickEvent)="toggleHideComments()"
            type="filter"
            class="pad_chips bg_size"
            size="s"
            [active]="hideAllComments ? true : false"
            leftIcon="comment">
          </vnc-chips>

          <vnc-chips
            (clickEvent)="filterFavorite()"
            type="filter"
            class="pad_chips bg_size"
            size="s"
            [active]="isFavorite ? true : false"
            [leftIcon]="'star'">
          </vnc-chips>

          <vnc-chips
            (clickEvent)="updateUnreadStatus()"
            type="filter"
            class="pad_chips bg_size"
            size="s"
            [active]="unreadOnly ? true : false"
            leftIcon="unread">
          </vnc-chips>

          <div>
            <vnc-chips
            [text]="allApplicationText"
            (clickEvent) = "openApplicationSmart()"
            rightIcon="mdi-chevron-down"
            size="s" type="filter"></vnc-chips>

            <div class="hide_smart_tag application_smart">
              <vnc-smart-link-filter-chip id="application-smart-filter" [disableApplyOnChange]="true"
              customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true" [showResetIcon]="true"
              (apply)="toggleApp($event)"
              applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
              (clearFilter)="setOrderBy($event)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT"
              [options]="availableAppsOptions"></vnc-smart-link-filter-chip>

              <ng-template #tagIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                    <defs>
                        <filter id="kelor6mofa" width="119.2%" height="119.7%" x="-9.6%" y="-9.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"/>
                            <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill="#8B96A0">
                            <g filter="url(#kelor6mofa)" transform="translate(-1587 -5259) translate(1545 4622)">
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M8.25 1.5c.375 0 .694.111.958.333l.097.089 6.75 6.75c.28.281.422.633.422 1.055 0 .375-.112.694-.334.958l-.088.096-5.274 5.274c-.281.28-.633.422-1.054.422-.375 0-.695-.112-.959-.334l-.096-.088-6.75-6.75c-.25-.25-.389-.556-.417-.917L1.5 8.25V2.977c0-.422.14-.774.422-1.055.25-.25.555-.389.917-.417l.138-.005H8.25zm.01 1.498L8.25 3H3v5.25l-.002.01 6.718 6.717h.02l5.241-5.24V9.715L8.26 2.998zm-3.39.764c.316 0 .58.105.79.316.211.211.317.475.317.791 0 .317-.106.58-.317.791-.21.211-.48.317-.808.317-.305 0-.563-.106-.774-.317-.21-.21-.316-.474-.316-.79 0-.317.105-.58.316-.792.211-.21.475-.316.791-.316z" transform="translate(24 146) translate(0 468) translate(16 20) translate(3 3)"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </ng-template>

            </div>
            
        </div>

          <vp-datepicker #datePickerWidget
            [lang]="lang"
            [selected]="selected"
            class="btn_header"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [showClearButton]="true"
            [showCancel]="true"
            [linkedCalendars]="true"
            [keepCalendarOpeningWithRange]="true"
            [showRangeLabelOnInput]="true"
            [placeholder]="'LAST_THREE_MONTH' | translate"
            (datesUpdatedEvent)="datesUpdated($event)"
            (resetDateFilterEvent)="resetDateFilter()"
          >
          <mat-icon class="mat-18">keyboard_arrow_down</mat-icon>
        </vp-datepicker>
      </div>

      <span class="border_verical pad_chips"></span>
      
      <div class="filter-container">
        <span class="global_text_filter">Filters</span>

        <div>
          <vnc-chips
          [text]="tagsText"
          class="pad_chips d-flex"
          (clickEvent) = "filterTags()"
          rightIcon="mdi-chevron-down"
          size="s" type="filter">
          </vnc-chips>

          <div class="hide_smart_tag tags_smart">
            <vnc-smart-link-filter-chip id="tags-smart-filter" [disableApplyOnChange]="true"
            customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true"
            (apply)="setTags($event)"
            [showResetIcon]="true"
            applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
            (clearFilter)="setTags($event.value)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT"
            [options]="tags"></vnc-smart-link-filter-chip>
          </div>
        </div>

        <div>
          <vnc-chips
            [text]="selectedUsers"
            (clickEvent) = "openUserFilter()"
            class="pad_chips d-flex"
            rightIcon="mdi-chevron-down"
            size="s" type="filter">
          </vnc-chips>

          <div class="hide_smart_tag">
            <mat-icon class="mat-18 reset-user-icon-filter" (click)="resetUserFilter()" *ngIf="selectedUsers!=='Users'">close</mat-icon>
          </div>

          <div class="hide_smart_tag user_smart">
            <vnc-smart-link-filter-chip  id="user-smart-filter" [searchPlaceholder]="'SEARCH' | translate"  [applyLabel]="'APPLY'" [cancelLabel]="'CANCEL'" [multiple]="true"  type="SELECT"
            (clearFilter)="applyAllUserFilters($event)" (apply)="applyAllUserFilters($event)"  [options]="contacts"
            [showClearIcon]="true"
            [showSearchBox]="true"
            [showResetIcon]="true"
            [showOperatorOption]="true"></vnc-smart-link-filter-chip>
          </div>
        </div>

        <div>
          <vnc-chips
            [text]="selectedAuthors"
            (clickEvent) = "openAuthorFilter()"
            rightIcon="mdi-chevron-down"

            size="s" type="filter">
          </vnc-chips>

          <div class="hide_smart_tag">
            <mat-icon class="mat-18 reset-author-icon-filter" (click)="resetAuthorsFilter()" *ngIf="selectedAuthors!=='Authors'">close</mat-icon>
          </div>

          <div class="hide_smart_tag author_smart">
            <vnc-smart-link-filter-chip  id="author-smart-filter" [searchPlaceholder]="'SEARCH' | translate"  [applyLabel]="'APPLY'" [cancelLabel]="'CANCEL'" [multiple]="true"  type="SELECT"
            (clearFilter)="applyAllAuthorFilters($event)" (apply)="applyAllAuthorFilters($event)"  [options]="contacts"
            [showClearIcon]="true"
            [showSearchBox]="true"
            [showResetIcon]="true"
            [showOperatorOption]="true">
            </vnc-smart-link-filter-chip>
          </div>
        </div>
      </div>

      <span class="border_verical pad_chips"></span>

      <div class="filter-container">
        <span class="order_text_change">Order </span>

        <div>
          <vnc-chips
            class="pad_chips d-flex"
            [text]="orderByText"
            (clickEvent) = "openOrderByFilter()"
            rightIcon="mdi-chevron-down"
            size="s" type="filter">

          </vnc-chips>

          <div class="hide_smart_tag order_by">
            <vnc-smart-link-filter-chip id="order-by-smart-filter" [disableApplyOnChange]="true"
            customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true"
            (apply)="setOrderBy($event)"
            applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
            (clearFilter)="setOrderBy($event)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT" [showResetIcon]="true"
            [options]="orderByOptions"></vnc-smart-link-filter-chip>
          </div>
        </div>

        <div>
          <vnc-chips
            [text]="groupByText"
            (clickEvent) = "openGroupByFilter()"
            rightIcon="mdi-chevron-down"
            size="s" type="filter">
          </vnc-chips>

          <div class="hide_smart_tag group_by">
            <vnc-smart-link-filter-chip id="group-by-smart-filter" [disableApplyOnChange]="true"
            customClass="dropdown-with-footer" [useMenuItem]="true" [showClearIcon]="true"
            (apply)="setGroupBy($event)"
            applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
            (clearFilter)="setGroupBy($event)" [prefixIconTemplate]="tagIcon" [multiple]="true" type="SELECT" [showResetIcon]="true"
            [options]="groupByOptions"></vnc-smart-link-filter-chip>
          </div>
        </div>
        
      </div>

    <div class="right-options">
      <div class="document-option show-unread-option" matTooltip="{{ (viewBy === 'condense' ? 'CONDENSED_VIEW' : viewBy === 'detail' ? 'EXPANDED_VIEW' : viewBy === 'tile' ? 'TILE_VIEW' : viewBy === 'table' ? 'TABLE_VIEW' : 'TILE_VIEW') | translate }}">
        <!-- <button id="viewByOption" mat-icon-button [matTooltip]="'SELECT_POSTS_VIEW' | translate"
          [matTooltipPosition]="'above'" [matTooltipClass]="'document-filter-button-tooltip'"
          [matMenuTriggerFor]="appMenu">
          <mat-icon class="current-view tog_btn"  fontSet="mdi" *ngIf="viewBy === 'condense'" fontIcon="mdi-view-headline">
          </mat-icon>
          <mat-icon class="current-view tog_btn" fontSet="mdi" *ngIf="viewBy === 'detail'" fontIcon="mdi-view-stream"></mat-icon>
          <vnc-icon type='product' name='view-tile-grid' width='16px' height='16px' *ngIf="viewBy === 'tile'" class="current-view-tile"></vnc-icon>
        </button> -->
        <vnc-action-panel [iconTemplate]="viewBy === 'condense' ? condensedIconTemplate : viewBy === 'detail' ? detailIconTemplate : viewBy === 'tile' ? tileIconTemplate : viewBy === 'table' ? tableViewIconTemplate : tileIconNewTemplate" actionColor="white" [actionTooltipText]="'SELECT_POSTS_VIEW' | translate" (actionClicked)="appMenuTrigger.openMenu()" [ngClass]="appMenuTrigger.menuOpen ? 'active':''" ></vnc-action-panel>
        <button #appMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="appMenu" style="width: 0; height: 0; border: none; min-width: 0;"></button>
        <mat-menu #appMenu="matMenu" [class]="'view-by-option-menu'">
          <ng-template matMenuContent>
            <div (click)="setViewBy('table')" [ngClass]="{'detailViewActive' : viewBy === 'table'}">
              <vp-dropdown-item [itemType]="'icon-drop-default'" label="{{ 'TABLE_VIEW' | translate }}"
                sufixImageType="{{viewBy === 'table' ? 'check' : null}}" [size]="'m'"
                [prefixIconTemplate]="tableViewIconTemplate"
                [sufixIconTemplate]="viewBy === 'table' ? checkedTableViewIconTemplate : null">
              </vp-dropdown-item>
            </div>

            <div (click)="setViewBy('condense')" [ngClass]="{'condenseViewActive' : viewBy === 'condense'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'CONDENSED_VIEW' | translate }}"
              sufixImageType="{{viewBy === 'condense' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="condensedIconTemplate"
              [sufixIconTemplate]="viewBy === 'condense' ? checkedCondenseIconTemplate : null" >
              </vp-dropdown-item>
            </div>



            <div (click)="setViewBy('detail')" [ngClass]="{'detailViewActive' : viewBy === 'detail'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'EXPANDED_VIEW' | translate }}"
              sufixImageType="{{viewBy === 'detail' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="detailIconTemplate"
              [sufixIconTemplate]="viewBy === 'detail' ? checkedDetailIconTemplate : null">
              </vp-dropdown-item>
            </div>


            <div (click)="setViewBy('tile')" [ngClass]="{'detailViewActive' : viewBy === 'tile'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'TILE_VIEW' | translate }}"
              sufixImageType="{{viewBy === 'tile' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="tileIconTemplate"
              [sufixIconTemplate]="viewBy === 'tile' ? checkedTileIconTemplate : null">
              </vp-dropdown-item>
            </div>
          </ng-template>
        </mat-menu>
        <ng-template #tileIconNewTemplate>
          <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.04 9.75H2.96c-.39 0-.71.338-.71.75V15c0 .412.32.75.71.75h12.08c.39 0 .71-.338.71-.75v-4.5c0-.412-.32-.75-.71-.75zm0-7.5H2.96c-.39 0-.71.337-.71.75v4.5c0 .412.32.75.71.75h12.08c.39 0 .71-.338.71-.75V3c0-.413-.32-.75-.71-.75z" fill="#8B96A0" fill-rule="evenodd"/>
          </svg>
        </ng-template>
        <ng-template #tableViewIconTemplate>
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/format-list-bulleted" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.500000, 4.500000)" fill="#8B96A0">
                      <path d="M1.5,6 C0.67,6 0,6.67 0,7.5 C0,8.33 0.67,9 1.5,9 C2.33,9 3,8.33 3,7.5 C3,6.67 2.33,6 1.5,6 L1.5,6 Z M1.5,0 C0.67,0 0,0.67 0,1.5 C0,2.33 0.67,3 1.5,3 C2.33,3 3,2.33 3,1.5 C3,0.67 2.33,0 1.5,0 L1.5,0 Z M1.5,12.17 C0.76,12.17 0.17,12.77 0.17,13.5 C0.17,14.23 0.77,14.83 1.5,14.83 C2.23,14.83 2.83,14.23 2.83,13.5 C2.83,12.77 2.24,12.17 1.5,12.17 L1.5,12.17 Z M4.5,14.5 L18.5,14.5 L18.5,12.5 L4.5,12.5 L4.5,14.5 L4.5,14.5 Z M4.5,8.5 L18.5,8.5 L18.5,6.5 L4.5,6.5 L4.5,8.5 L4.5,8.5 Z M4.5,0.5 L4.5,2.5 L18.5,2.5 L18.5,0.5 L4.5,0.5 L4.5,0.5 Z" id="Shape"></path>
                  </g>
              </g>
          </svg>
        </ng-template>
        <ng-template #checkedTableViewIconTemplate>
          <svg width="18" height="18" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon id="Shape" fill="#337cbd" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
              </g>
          </svg>
        </ng-template>

        <ng-template #condensedIconTemplate>
        <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Icon/product/listview-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(3.000000, 5.000000)" fill="#8B96A0">
                    <path d="M17.0066023,10 C17.5158786,10 17.9359082,10.389657 17.993314,10.8916552 L18,11.0093689 L18,12.9906311 C18,13.5162171 17.6165086,13.936038 17.1224535,13.9933309 L17.0066023,14 L0.993397683,14 C0.484121389,14 0.0640918428,13.610343 0.00668597047,13.1083448 L0,12.9906311 L0,11.0093689 C0,10.4837829 0.38349143,10.063962 0.877546527,10.0066691 L0.993397683,10 L17.0066023,10 Z M17.0066023,5 C17.5158786,5 17.9359082,5.38965696 17.993314,5.89165516 L18,6.0093689 L18,7.9906311 C18,8.51621713 17.6165086,8.936038 17.1224535,8.99333085 L17.0066023,9 L0.993397683,9 C0.484121389,9 0.0640918428,8.61034304 0.00668597047,8.10834484 L0,7.9906311 L0,6.0093689 C0,5.48378287 0.38349143,5.063962 0.877546527,5.00666915 L0.993397683,5 L17.0066023,5 Z M17.0066023,0 C17.5158786,0 17.9359082,0.389656961 17.993314,0.89165516 L18,1.0093689 L18,2.9906311 C18,3.51621713 17.6165086,3.936038 17.1224535,3.99333085 L17.0066023,4 L0.993397683,4 C0.484121389,4 0.0640918428,3.61034304 0.00668597047,3.10834484 L0,2.9906311 L0,1.0093689 C0,0.48378287 0.38349143,0.0639619973 0.877546527,0.00666914824 L0.993397683,0 L17.0066023,0 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
        </ng-template>
        <ng-template #checkedCondenseIconTemplate>
          <svg width="18" height="18" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon id="Shape" fill="#337cbd" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
              </g>
          </svg>
        </ng-template>

        <ng-template #detailIconTemplate>
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/tile-view-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(3.000000, 3.000000)" fill="#8B96A0">
                      <path d="M17.0526316,10 L0.947368421,10 C0.426315789,10 0,10.45 0,11 L0,17 C0,17.55 0.426315789,18 0.947368421,18 L17.0526316,18 C17.5736842,18 18,17.55 18,17 L18,11 C18,10.45 17.5736842,10 17.0526316,10 L17.0526316,10 Z M17.0526316,0 L0.947368421,0 C0.426315789,0 0,0.45 0,1 L0,7 C0,7.55 0.426315789,8 0.947368421,8 L17.0526316,8 C17.5736842,8 18,7.55 18,7 L18,1 C18,0.45 17.5736842,0 17.0526316,0 L17.0526316,0 Z" id="Shape"></path>
                  </g>
              </g>
          </svg>
        </ng-template>
        <ng-template #checkedDetailIconTemplate>
          <svg width="18" height="18" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/check</title>
              <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon id="Shape" fill="#337cbd" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
              </g>
          </svg>
        </ng-template>

        <ng-template #tileIconTemplate>
          <!-- <vnc-icon type='product' name='view-tile-grid' width='16px' height='16px' *ngIf="viewBy !== 'tile'" class="tile-view-icon"></vnc-icon>
          <vnc-icon type='product' name='view-tile-grid' width='16px' height='16px' class="current-view-tile" *ngIf="viewBy === 'tile'"></vnc-icon> -->
          
          
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/view-tile-grid" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(3.000000, 5.000000)" fill="#8B96A0">
                      <path d="M4.00019251,6 C4.55237094,6 5,5.54902482 5,5.00922203 L5,0.990777969 C5,0.443586406 4.55628335,0 4.00019251,0 L0.999807492,0 C0.447629061,0 0,0.45097518 0,0.990777969 L0,5.00922203 C0,5.55641359 0.443716645,6 0.999807492,6 L4.00019251,6 Z M4.00019251,14 C4.55237094,14 5,13.5490248 5,13.009222 L5,8.99077797 C5,8.44358641 4.55628335,8 4.00019251,8 L0.999807492,8 C0.447629061,8 0,8.45097518 0,8.99077797 L0,13.009222 C0,13.5564136 0.443716645,14 0.999807492,14 L4.00019251,14 Z M11.0001925,14 C11.5523709,14 12,13.5490248 12,13.009222 L12,8.99077797 C12,8.44358641 11.5562834,8 11.0001925,8 L7.99980749,8 C7.44762906,8 7,8.45097518 7,8.99077797 L7,13.009222 C7,13.5564136 7.44371665,14 7.99980749,14 L11.0001925,14 Z M18.0001925,14 C18.5523709,14 19,13.5490248 19,13.009222 L19,8.99077797 C19,8.44358641 18.5562834,8 18.0001925,8 L14.9998075,8 C14.4476291,8 14,8.45097518 14,8.99077797 L14,13.009222 C14,13.5564136 14.4437166,14 14.9998075,14 L18.0001925,14 Z M11.0001925,6 C11.5523709,6 12,5.54902482 12,5.00922203 L12,0.990777969 C12,0.443586406 11.5562834,0 11.0001925,0 L7.99980749,0 C7.44762906,0 7,0.45097518 7,0.990777969 L7,5.00922203 C7,5.55641359 7.44371665,6 7.99980749,6 L11.0001925,6 Z M14,5.00922203 C14,5.55641359 14.4437166,6 14.9998075,6 L18.0001925,6 C18.5523709,6 19,5.54902482 19,5.00922203 L19,0.990777969 C19,0.443586406 18.5562834,0 18.0001925,0 L14.9998075,0 C14.4476291,0 14,0.45097518 14,0.990777969 L14,5.00922203 Z" id="Shape"></path>
                  </g>
              </g>
          </svg>

        </ng-template>
        <ng-template #checkedTileIconTemplate>
          <svg width="18" height="18" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/check</title>
              <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon id="Shape" fill="#337cbd" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
              </g>
          </svg>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div id="document-list" vpScrollList (onUserScroll)="onUserScroll($event);">
  <div class="new-posts-notification" *ngIf="isLoggedIn && newMessageCount > 0" (click)="refreshFeed()">
    <mat-icon>arrow_upward</mat-icon> {{'SEE_NEW_POSTS' | translate }}</div>
  <ng-container *ngIf="groupBy === 'none'">
    <div class="document-item" *ngFor="let document of documents">
      <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
      <vp-condensed-view *ngIf="viewBy === 'condense'" [document]="document"></vp-condensed-view>
    </div>
    <div *ngIf="viewBy === 'tile' && groupBy === 'none'">
      <div *ngIf="talkFeeds && talkFeeds.length > 0">
        <div class="flex-cove">
          <img class="document-item app-logo-cat" src="assets/icon/new-icons/vnctalk.svg" />
          <p class="app-logo-cat-text">VNCtalk<span class="sub-text"> ({{talkFeeds.length}})</span></p>
          <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
        </div>
        <div class="tile-view-container">
          <div class="document-item" *ngFor="let document of talkFeeds">
            <vp-tile-view  [document]="document"></vp-tile-view>
          </div>
        </div>
      </div>

      <div *ngIf="mailFeeds && mailFeeds.length > 0" class="tile-cat-sec">
        <div class="flex-cove">
          <img class="document-item app-logo-cat" src="assets/icon/new-icons/vncmail.svg" />
          <p class="app-logo-cat-text">VNCmail<span class="sub-text"> ({{mailFeeds.length}})</span></p>
          <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
        </div>
        <div class="tile-view-container">
          <div class="document-item" *ngFor="let document of mailFeeds">
            <vp-tile-view  [document]="document"></vp-tile-view>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-container *ngIf="groupBy === 'application'">
    <section class="docs-by-app" *ngFor="let app of apps">
      <div class="app-header {{app}}-app" (click)="toggleStatus[app] = !toggleStatus[app]">
        <div class="app-name">
            VNC{{ app }}
            <span class="app-results-count" *ngIf="appsList[app]">{{ appsList[app].length }}</span>
        </div>
        <span></span>
        <button mat-icon-button (click)="$event.stopPropagation(); toggleStatus[app] = !toggleStatus[app]">
          <mat-icon *ngIf="toggleStatus[app]" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
          <mat-icon *ngIf="!toggleStatus[app]" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </button>
      </div>
      <div class="app-content" *ngIf="!toggleStatus[app]">
        <div class="document-item" *ngFor="let document of appsList[app]">
          <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
          <vp-condensed-view *ngIf="viewBy === 'condense'" [document]="document"></vp-condensed-view>
        </div>
        <div *ngIf="viewBy === 'tile' && !toggleStatus[app]">
          <div *ngIf="talkFeeds && talkFeeds.length > 0">
            <div class="flex-cove">
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vnctalk.svg" />
                <p class="app-logo-cat-text">VNCtalk<span class="sub-text"> ({{talkFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
            </div>
            <div class="tile-view-container">
              <div class="document-item" *ngFor="let document of talkFeeds">
                <vp-tile-view  [document]="document"></vp-tile-view>
              </div>
            </div>
          </div>

          <div *ngIf="mailFeeds && mailFeeds.length > 0" class="tile-cat-sec">
            <div class="flex-cove">
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vncmail.svg" />
                <p class="app-logo-cat-text">VNCmail<span class="sub-text"> ({{mailFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
              </div>
            <div class="tile-view-container">
              <div class="document-item" *ngFor="let document of mailFeeds">
                <vp-tile-view  [document]="document"></vp-tile-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="groupBy === 'user'">
    <section class="docs-by-app docs-by-user" *ngFor="let user of users">
      <div class="app-header app-header-default" (click)="toggleStatus[user] = !toggleStatus[user]">
        <div class="app-name">
            {{ user | vpGetFullName }}
            <span class="app-results-count" *ngIf="usersList[user]">{{ usersList[user].length }}</span>
        </div>
        <span></span>
        <button mat-icon-button (click)="$event.stopPropagation(); toggleStatus[user] = !toggleStatus[user]">
          <mat-icon *ngIf="toggleStatus[user]" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
          <mat-icon *ngIf="!toggleStatus[user]" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </button>
      </div>
      <div class="app-content" *ngIf="!toggleStatus[user]">
        <div class="document-item" *ngFor="let document of usersList[user]">
          <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
          <vp-condensed-view *ngIf="viewBy === 'condense'" [document]="document"></vp-condensed-view>
        </div>
        <div *ngIf="viewBy === 'tile' && !toggleStatus[user]">
            <div *ngIf="talkFeeds && talkFeeds.length > 0">
              <div>
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vnctalk.svg" />
                <p class="app-logo-cat-text">VNCtalk<span class="sub-text"> ({{talkFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
              </div>
              <div class="tile-view-container">
                <div class="document-item" *ngFor="let document of talkFeeds">
                  <vp-tile-view  [document]="document"></vp-tile-view>
                </div>
              </div>
            </div>

            <div *ngIf="mailFeeds && mailFeeds.length > 0" class="tile-cat-sec">
              <div>
                <img class="document-item app-logo-cat" src="assets/icon/new-icons/vncmail.svg" />
                <p class="app-logo-cat-text">VNCmail<span class="sub-text"> ({{mailFeeds.length}})</span></p>
                <vnc-icon type='product' name='keyboard-arrow-up-new' width='18px' height='18px' class="toggle-icon"></vnc-icon>
              </div>
              <div class="tile-view-container">
                <div class="document-item" *ngFor="let document of mailFeeds">
                  <vp-tile-view  [document]="document"></vp-tile-view>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  </ng-container>
  <vnc-no-results *ngIf="totalResults === 0 && !isLoading" description="" message="{{ 'NO_RESULTS' | translate }}"></vnc-no-results>
</div>

<span class="Loading app-loading" *ngIf="isLoading">
  <div class="loading_wrap">
    <div class="loader"></div>  &nbsp;&nbsp;&nbsp; Loading
  </div>
</span>
