<div class="vnc-input-chips">
    <mat-form-field class="vnc-chip-list">
    <mat-icon *ngIf="iconName" class="prefix-icon" fontSet="mdi" fontIcon="{{ iconName }}"></mat-icon>
    <mat-chip-list #chipList  (change)="onChange($event)">
        <!-- <mat-chip
            *ngFor="let item of items"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(item)">
            <span>{{item }}</span>
            <mat-icon matChipRemove *ngIf="removable" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </mat-chip> -->
        <input
            placeholder="{{ newItemLabel }}"
            #itemInput
            [formControl]="itemCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
            (keydown)="onKeydown($event)"
            >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{item}}
    </mat-option>
    </mat-autocomplete>
</mat-form-field>
</div>