import { createAction } from "@ngrx/store";
import { Action } from ".";
import { ITask } from "../common/models/task.model";

export class TaskActionTypes {
    static TASK_LIST_ADD = "[TASK] Add Task List";
}

export const AllTaskList = createAction("[TASK] Add Task List");

export class TaskAddList implements Action {
    readonly type = TaskActionTypes.TASK_LIST_ADD;
    constructor(public payload: {tasks: ITask[]}) {
    }
}