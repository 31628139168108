/* eslint no-console: 0 */
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { getContactStatusById, getIsLoggedIn, getUserProfile, getFederatedApps, RootState } from "../reducers/index";
  import { Store } from "@ngrx/store";
  import {
    getArchivedConversations,
    getConversationById,
    getConversationConfig,
    getConversationIds,
    getConversationMembers,
    getConversations,
    getIsSelectedConversationMessagesLoading,
    getIsSelectedConversationMessagesOnLastPage,
    getLastMessageByConversationTarget,
    getMessagesByConversationTarget,
    getIsActivatedConversation,
    getSelectedConversation,
    getSelectedConversationText,
    getUnArchivedConversations,
    getMessageById,
    getMessageIdsByConversationTarget,
    getConversationOwner,
    getSelectedConversationMembers,
    getAllConversationsUnreadCount} from "../reducers";
  import {
    getNetworkInformation
  } from "../reducers";
  import { combineLatest, interval, Observable } from "rxjs";
  import { environment } from "../../environments/environment";
  import { Injectable } from "@angular/core";
  import {
    DeleteMessages,
    MessageAdd,
    MessageBulkAppend,
    MessageBulkAppendMultiConversation,
    MessageBulkLoading,
    MessageBulkLoadingFailed,
    MessageDeleteAction,
    MessageDeletedStatusUpdateAction,
    MessageFavoriteUpdateAction,
    MessageLastPageLoaded,
    MessageStatusUpdateAction,
    MultiConversationMessageAdd,
    MultiConversationMessageDeletedStatusUpdateAction,
    ResetMessages,
    SelectMessage,
    UnselectMessage,
    MessageBulkLoaded,
    MessageUpdateAction
  } from "../actions/message";
  import { XmppService } from "../services/xmpp.service";
  import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
  import {
    ArchiveConversation,
    ConversationBlockListAdd,
    ConversationBlockListRemove,
    ConversationCreate,
    ConversationDelete,
    ConversationNextLoadSuccess,
    ConversationRemoveMember,
    ConversationSelect,
    ConversationUpdateMembers,
    MultiConversationUpdateMembers,
    UnArchiveConversation,
    UpdateFileInProgress,
    RemoveUploadedFile,
    ResetLastPageLoaded,
    ConversationInActive,
    UpdateNotificationSetting,
    UpdateSoundSetting,
    SetActiveConversation,
    UpdateConversationRoomId,
    ConversationUpdateOwner,
    MultiConversationUpdateOwner,
    ConversationResetUnread,
    ConversationMultipleDelete,
    ConversationRemoveMembers,
    ConversationUpdate,
    ConversationUpdateBroadcastData
  } from "../actions/conversation";
  import { BehaviorSubject } from "rxjs";
  import { Subject } from "rxjs";
  import { Router } from "@angular/router";
  import { of, forkJoin } from "rxjs";
  import { TranslateService } from "@ngx-translate/core";
  import { DatetimeService } from "../services/datetime.service";
  import { bufferTime, distinctUntilChanged, filter, finalize, map, pairwise, retry, sampleTime, skip, switchMap, take, tap} from "rxjs/operators";
  import { MatSnackBar } from "@angular/material/snack-bar";
  import format from "date-fns/format";
import { Conversation } from "../common/models/conversation.model";
import { AppService } from "../services/app.service";
import { AuthService } from "../common/providers/auth.service";
import { ConfigService } from "../config.service";
import { ElectronService } from "../services/electron.service";
import { CommonUtils } from "../common/utils/common-util";
import { AppRepository } from "./app.repository";
  
    const TOTAL_GROUP_TO_JOIN = 10;

    const URL_REGEXP = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;()\s]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const TICKET_MENTION = "ticket_mention";
    const META_TASK_MENTION = "meta_task_mention";
    const MESSAGE_DECODER_SECRET_KEY = `<span class="hidden-secret-key">_._{{ USERNAME }}</span>`;
    const TOPIC_MENTION_MESSAGE_RECEIVER = "You are mentioned in a comment of this topic:";
    const TOPIC_MENTION_MESSAGE_SENDER = "You've mentioned {{ USERNAME }} in a comment of this topic:";
  
  
  @Injectable()
  export class ConversationRepository {
    messageToReply: any;
    userJID: any;
    currentConv: Conversation;
    //
    currentConvUnreadIds: string[];
    currentMentionedMessageId: string;
    //
    openSearch$ = new Subject<any>();
    contentForClipBoard = {};
    private joinedConversations: string[] = [];
    private joinedConversationsLater: string[] = [];
    // private colors = ConstantsUtil.CHAT_BUBBLE_COLORS;
    // private pendingMessages: Message[] = [];
    private pendingReadRequest = {};
    private isConnectedXMPP = false;
    private isAppOnline = false;
    private shouldMark = true;
    private isLoggedIn: boolean;
    private storePendingRequest = new Subject();
    sendReceipt: boolean;
    subscribedUsers = [];
    allowScreenSharing: boolean;
    item: Notification;
    private messageUpdates$ = new Subject<any>();
    public callInvitationType: string;
    public callInvitationName: string;
    pendingPadReadRequest: any = {};
    lastMarkedAsRead: number;
    lockMarkAsRead: boolean;
    storePendingPadRequest: any = new Subject();
    isGroupManageEnabled: boolean = false;
    isAllRoomsMembersSynced = false;
    leftRoomList = [];
    joinedPublicList = [];
    triggerGallary = new Subject<any>();
    bootstrapped: boolean = false;
    private _onPresenceMuc$ = new Subject<any>();
    private _onPresenceMucAddMember$ = new Subject<any>();
    private _onNickChange$ = new Subject<any>();
    private _removeMessagesFromPendings$ = new Subject<any>();
  
    private presencesErrorsToIgnore = {};
  
    // public isRedirectedToChatViaChromeNotification: boolean;
    organizationId: number;
    apps: any = {};
    loadedGroupInfo = [];
    recentChat$ = new BehaviorSubject<string[]>([]);
    runningConferences$ = new BehaviorSubject<string[]>([]);
    scheduledConferences = [];
    isOnNativeMobileDevice: boolean;
    scheduledConferencesShow: boolean;
    deletedMessages: string[] = [];
    lastTimeCheck: any = {};
    processingE2EMessages = [];
    decryptedMessages: any = [];
    isConvHistoryRunning: boolean;
    isSendingPendingFiles: boolean;
    previousSyncTimestamp: number;
    sendAttachmentSubscription: any = {};
    downloadFileSubscription: any = {};
    downloadChunkArray: any = {};
    constructor(
        // private conversationService: ConversationService,
      private xmppService: XmppService,
      private translate: TranslateService,
      private appRepository: AppRepository,
    ) {
        this.xmppService.getOnDeleteMessage().pipe(bufferTime(200), filter(res => res.length > 0))
        .subscribe((ids) => {
          this.deletedMessages = [...this.deletedMessages, ...ids]
        });
    }
  
    parseMentionConversationContent(content: string) {
        let translations: any = {};
        this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE",
          "TICKET_MENTION_SENDER_MESSAGE",
          "META_TASK_MENTION", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
            translations = v;
          });
        try {
          if (CommonUtils.parseTicketMentions(TICKET_MENTION, content).length > 0) {
            const textToReplace = CommonUtils.parseTicketMentions(TICKET_MENTION, content)[0];
            let userjid = textToReplace.split("#")[1];
            let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.appRepository.getFullName(userjid))
            if (this.userJID?.bare === userjid) {
              mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.appRepository.getFullName(userjid))
            }
            content = content.replace(textToReplace, mentionText);
          } else if (CommonUtils.parseTicketMentions(META_TASK_MENTION, content).length > 0) {
            const textToReplace = CommonUtils.parseTicketMentions(META_TASK_MENTION, content)[0];
            let userjid = textToReplace.split("#")[1];
            let mentionText = translations.META_TASK_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.appRepository.getFullName(userjid))
            if (this.userJID?.bare === userjid) {
              mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.appRepository.getFullName(userjid))
            }
            content = content.replace(textToReplace, mentionText);
          }
        } catch (error) {
    
        }
        return content;
      }
  }
  